/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext, useMemo} from "react";
import {Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import objectPath, { push } from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import { UserContext } from "../../../../../app/providers/User";
import { AppContext } from "../../../../../app/providers/App";

export function UserProfileDropdown() {
  const {user, setSession, closeSession} = useContext(UserContext);
  const {push} = useHistory();
  const {axios} = useContext(AppContext);  
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  return (
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
        >
          <div className={"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"}>
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hola,</span>         
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
              {user.username}
            </span>
            <span className="symbol symbol-35">            
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user.username[0]}</span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (<>
              <div className="d-flex align-items-center p-8 rounded-top">

                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  {user.avatar == "" ?
                    <span className="symbol symbol-35">            
                      <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user.username[0]}</span>
                    </span>
                  :
                    <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt=""/>
                  }
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.username}</div>                
              </div>
              <div className="separator separator-solid"></div>
            </>)}

            {!layoutProps.light && (
                <div
                    className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
                    }}
                >
                  <div className="symbol bg-white-o-15 mr-3">
                    <span className="symbol-label text-success font-weight-bold font-size-h4">S</span>
                    {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                  </div>
                  <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                    {user.username}
                  </div>
                  <span className="label label-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span>
                </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success"/>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Mi Perfil
                  </div>
                  <div className="text-muted">
                    Panel de Configuración{` `}                    
                  </div>
                </div>
              </div>
            </a>            
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer  px-8 py-5">
              <button onClick={() => closeSession() } className="btn btn-light-primary font-weight-bold">
                Sign Out
              </button>              
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
}
