import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { useGet } from "../hooks/useGet/index.ts";

export const MyPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("DigitalCards");
    const { data, error, loading } = useGet("users/", []);    
    return <>
    
    </>;
};
