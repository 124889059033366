import React, { useState, useContext } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";

import { useGet } from "../../../../../hooks/useGet/index.ts";
import ReactMediaLibraryWrapper from "../../customers/Media";
import { Card } from "../../../../../components/Card";
import SketchColorPicker from "../../../../../components/ColorPicker/ColorPicker";
import { AppContext } from "../../../../../providers/App";
import { UserContext } from "../../../../../providers/User";

// Validation schema
const ProductEditSchema = Yup.object().shape({
  general: Yup.object().shape({
    name: Yup.string()
      .min(2, "Minimo 3 caracteres")
      .required("El nombre es un campo obligatorio"),
    description: Yup.string()
      .min(2, "Minimo 3 caracteres")
      .required("El nombre es un campo obligatorio"),
  }),
});

export const trimString = (text) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .split(" ")
    .join("-");

export function ProductEditForm({ btnRef, saveProduct }) {
  const { data: types } = useGet(`categories/1`, []);
  const card = {
    card_type_id: 1,
    slug: "",
    general: {
      primary_color: "#26548e",
      name: "",
      description: "",
      code: "+52",
      website: "",
    },
    categories: [],
    direction: {
      lat: "",
      lng: "",
      city: "",
      cp: "",
      country: "",
      address: "",
    },
    avatar_id: 0,
    background_image_id: 0,
  };
  const { data: form, loading } = useGet(`cardTypes/${card.card_type_id}`, {});

  const [avatar, setAvatar] = useState({ path: "media/users/blank.png" });
  const [bg, setBg] = useState({ path: "" });
  const { axios } = useContext(AppContext);
  const { getToken } = useContext(UserContext);
  const [touched, setTouched] = useState(false);
  const [card_id, setCard_id] = useState(0);
  if (loading) {
    return <span> </span>;
  }
  for (const key in form) {
    const element = form[key];
    if (Array.isArray(element)) {
      card[key] = element;
    }
  }
  function printForm(i, fn, hc, v) {
    let formToPrint = [];
    for (const key in form) {
      if (Array.isArray(form[key])) {
        const element = form[key];
        const arrayName = key;
        element.forEach(({ fieldKey, description }, index) => {
          const name = fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1);
          formToPrint.push(
            <>
              <div className='col-lg-1'>
                <Field
                  style={{ height: "15px", marginTop: "20px" }}
                  key={fieldKey}
                  type='checkbox'
                  name={`${arrayName}[${index}].enabled`}
                  component={i}
                  label={" "}
                />
              </div>
              <div className={description ? "col-lg-5" : "col-lg-11"}>
                <Field
                  key={fieldKey}
                  type='text'
                  name={`${arrayName}[${index}].href`}
                  component={i}
                  placeholder={`Igresa la url para el ${fieldKey}`}
                  onChange={(e) => {
                    fn(`${arrayName}[${index}].fieldKey`, fieldKey);
                    hc(e);
                  }}
                  value={v[arrayName][index].href}
                  label={name}
                />
              </div>
              {description && (
                <div className='col-lg-6 mb-3'>
                  <Field
                    key={fieldKey}
                    type='text'
                    name={`${arrayName}[${index}].description`}
                    component={i}
                    placeholder={`${description}`}
                    value={v[arrayName][index].description}
                    label=' '
                  />
                </div>
              )}
              <div>
                <Field
                  key={fieldKey}
                  type='text'
                  name={`${arrayName}[${index}].base_url`}
                  component={i}
                  placeholder={`Igresa el ${fieldKey}`}
                  value={v[arrayName][index].base_url}
                  hidden
                />
              </div>
              <div className='col-lg-6 d-none'>
                <Field
                  key={fieldKey}
                  type='text'
                  name={`${arrayName}[${index}].fieldKey`}
                  component={i}
                  placeholder={`Igresa el ${fieldKey}`}
                  label={fieldKey}
                  value={v[arrayName][index].fieldKey}
                  hidden
                />
              </div>
            </>
          );
        });
      }
    }
    return formToPrint;
  }
  return (
    <>
      <div className='row'>
        <Formik
          enableReinitialize={true}
          initialValues={card}
          validationSchema={ProductEditSchema}
          onSubmit={async (values) => {            
            try {
              if (touched) {
                await axios.put(`/api/v1/cards/${card_id}`, values, {                  
                  headers: { authorization: getToken() },
                });
                
              } else {
                const response = await axios.post("/api/v1/cards", values, {
                  headers: { authorization: getToken() },
                });
                setTouched(true);                
                setCard_id(response.data.card.id);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({ handleSubmit, values, setFieldValue, handleChange }) => {
            function custom(item) {
              setAvatar(item);
              setFieldValue("avatar_id", item.id);
            }
            function customBackground(item) {
              setBg(item);
              setFieldValue("background_image_id", item.id);
            }
            function color(item) {
              setFieldValue("general.primary_color", `${item}`);
            }
            function deleteImg(fn, field) {
              fn({ path: "" });
              setFieldValue(field, 0);
            }
            return (
              <>
                <Form className='form form-label-right col-md-8'>
                  <div className='form-group row'>
                    <div className='col-lg-12 mb-3'></div>
                    <div className='col-lg-12 mb-3'>
                      <Field
                        name='general.name'
                        component={Input}
                        placeholder='Ingresa el Nombre'
                        label='Nombre'
                      />
                    </div>
                    <div className='col-lg-12'>
                      <Field
                        name='slug'
                        component={Input}
                        placeholder={`slug por defecto ${trimString(
                          values.general.name
                        )}`}
                        label='Ingresa tu slug'
                      />
                    </div>
                    <div className='col-lg-12 my-3'>
                      <Field
                        name='general.description'
                        component={Input}
                        placeholder='Ingresa tú descripción'
                        label='Descripción'
                      />
                    </div>
                    <div className='col-lg-12 my-3'>
                      <Field
                        name='general.website'
                        component={Input}
                        placeholder='Ingresa tú sitio web'
                        label='Sitio Web'
                      />
                    </div>

                    <div className='col-lg-12'>
                      <Field
                        name='general.primary_color'
                        component={Input}
                        label='Color Principal'
                        hidden
                      />
                      <SketchColorPicker setParentColor={color} />
                    </div>
                    <div className='col-lg-6 my-3'>
                      <Field
                        name='avatar_id'
                        component={Input}
                        label='Avatar'
                        hidden
                      />
                      {values.avatar_id === 0 ? (
                        <ReactMediaLibraryWrapper
                          title={"Añadir imagen"}
                          setParentItem={custom}
                        />
                      ) : (
                        <>
                          <div
                            className='image-input image-input-outline'
                            id='kt_profile_avatar'
                            style={{
                              backgroundImage: "url('media/users/blank.png')",
                            }}
                          >
                            <div
                              className='image-input-wrapper'
                              style={{
                                backgroundImage: "url(" + avatar.path + ")",
                              }}
                            ></div>
                            <span
                              onClick={() => {
                                deleteImg(setAvatar, "avatar_id");
                              }}
                              className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                              data-action='remove'
                              data-toggle='tooltip'
                              title=''
                              data-original-title='Remove avatar'
                            >
                              <i className='ki ki-bold-close icon-xs text-muted'></i>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='col-lg-3 my-3'>
                      <Field
                        name='background_image_id'
                        component={Input}
                        label='Imagen de Fondo'
                        hidden
                      />
                      {values.background_image_id === 0 ? (
                        <ReactMediaLibraryWrapper
                          title={"Añadir imagen"}
                          setParentItem={customBackground}
                        />
                      ) : (
                        <>
                          <div
                            className='image-input image-input-outline'
                            id='kt_profile_avatar'
                            style={{
                              backgroundImage: "url('media/users/blank.png')",
                            }}
                          >
                            <div
                              className='image-input-wrapper'
                              style={{
                                backgroundImage: "url(" + bg.path + ")",
                              }}
                            ></div>
                            <span
                              onClick={() => {
                                deleteImg(setBg, "background_image_id");
                              }}
                              className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                              data-action='remove'
                              data-toggle='tooltip'
                              title=''
                              data-original-title='Remove avatar'
                            >
                              <i className='ki ki-bold-close icon-xs text-muted'></i>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-lg-4'>
                      <Field
                        name='direction.address'
                        placeholder='Ingresa la dirección'
                        component={Input}
                        label='Dirección'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Field
                        name='direction.cp'
                        placeholder='Ingresa el código postal'
                        component={Input}
                        label='CP'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Field
                        name='direction.city'
                        placeholder='Ingresa el estado'
                        component={Input}
                        label='Estado'
                      />
                    </div>
                    <div className='col-lg-4 my-3'>
                      <Field
                        name='direction.country'
                        placeholder='Ingresa el país'
                        component={Input}
                        label='País'
                      />
                    </div>
                  </div>
                  {loading ? (
                    <span>loader</span>
                  ) : (
                    <div className='form-group row dinamicos'>
                      {printForm(Input, setFieldValue, handleChange, values)}
                    </div>
                  )}
                  <div className='form-group row'>
                    <div className='col-lg-6 mb-3'>
                        <Field
                          name='direction.lat'
                          component={Input}
                          placeholder='Latitud'
                          label='Latitud'
                        />
                    </div>                    
                    <div className='col-lg-6 mb-3'>
                        <Field
                          name='direction.lng'
                          component={Input}
                          placeholder='Longitud'
                          label='Longitud'
                        />
                    </div>  
                  </div>
                  <h3>Categorias</h3>
                  <div className='form-group row'>
                    {types.map((data, index) => (
                      <div
                        key={index}
                        className='col-lg-4 my-3'
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <Field
                          type='text'
                          name={`categories[${index}].value`}
                          component={Input}
                          hidden
                        />
                        <Field
                          key={data.id}
                          style={{
                            height: "20px",
                            width: "20px",
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                          component={Input}
                          type='checkbox'
                          name={`categories[${index}].enabled`}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue(`categories[${index}].value`, data);
                          }}
                          label={data.name}
                        />
                        
                      </div>
                    ))}
                  </div>                                    
                  <button
                    type='submit'
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
                <div className='col-md-4' style={{ overflow: "hidden" }}>
                  <Card
                    form={values}
                    avatar={avatar.path}
                    bg={bg.path}
                    baseForm={form}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
