import * as Axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { ENDPOINT } from "../../endpoint";
import { UserContext } from "./User";

export const AppContext = createContext({
    loading: true,
    setLoading: () => null,
    axios: Axios,
});

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const instance = Axios.create({
        baseURL: ENDPOINT,
        withCredentials: true,
    });

    return (
        <AppContext.Provider value={{ axios: instance, setLoading, loading }}>
            {children}
        </AppContext.Provider>
    );
};
