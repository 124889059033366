import React, { useEffect, useMemo, useContext } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import {useSectionUIContext} from "../SectionUIContext";
import { AppContext } from "../../../../../providers/App";
import { UserContext } from "../../../../../providers/User";
import { useGet } from "../../../../../hooks/useGet/index.ts";

export function SectionDeleteDialog({ id, show, onHide }) {
  // Section UI Context
  const customersUIContext = useSectionUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams
    };
  }, [customersUIContext]);
  const { axios } = useContext(AppContext);
  const { getToken } = useContext(UserContext);
  // Section Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteSection = async () => {    
    console.log(id);
    try {
      const response = await axios.delete(`/api/v1/users/${id}`, {"headers": {"authorization": getToken()}});      
      if (response.data.error) {
        throw new Error(response.data.msg);
      }       
      axios.get(`/api/v1/users/`);
      customersUIProps.setIds([]);
      onHide();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Eliminar Usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>¿Estas seguro de eliminar el usuario?</span>
        )}
        {isLoading && <span>El usuario esta siendo eliminado...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancelar
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSection}
            className="btn btn-primary btn-elevate"
          >
            Eliminar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
