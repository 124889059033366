import React, { useEffect } from "react";
import CardHeader from "./Header";
import CardContent from "./Content";
import CardFooter from "./Footer";
import "./styles.css";

export const Card = ({ form, avatar, bg, baseForm }) => {
  document.documentElement.style.setProperty(
    "--colorprimary",
    form.general.primary_color
  );
  let buttons = [];
  let icons = [];
  for (const key in form) {
    if (Array.isArray(form[key]) && key !== "categories") {
      const array = form[key];
      array.forEach((element, index) => {
        if (baseForm[key][index].important) {
          buttons.push({ ...baseForm[key][index], ...element });
        } else {
          icons.push({ ...baseForm[key][index], ...element });
        }
      });
    }
  }

  return (
    <div className='digitalcard location'>
      <CardHeader />
      <CardContent
        icons={icons}
        buttons={buttons}
        name={form.general.name}
        bg={bg}
        description={form.general.description}
        avatar={avatar}
      />
      <CardFooter direction={form.direction} />
    </div>
  );
};
