import React, { useContext, useEffect } from 'react';
import { AppContext } from '../providers/App';
import { UserContext } from '../providers/User';
import AdminRoutes from './AdminRoutes';
import AuthRoutes from './AuthRoutes';
import EditorRoutes from './EditorRoutes';
import UserRoutes from './UserRoutes';

const Router = () => {
	const { loading } = useContext(AppContext);
	const { user } = useContext(UserContext);

	useEffect(() => {}, [user]);

	if (loading) {
		return <div></div>;
	}

	if (user === false || user === null || user.role === '') {
		return <AuthRoutes />;
	} else if (user.role === 'admin') {
		return <AdminRoutes />;
	} else if (user.role === 'editor') {
		return <EditorRoutes />;
	} else {
		return <UserRoutes />;
	}
};

export default Router;
