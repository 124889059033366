import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useGet } from "../../../../../hooks/useGet/index.ts";

export function SectionEditDialogHeader({ id }) {
  // Section Redux state  
  const { data} = useGet(`users/${id}`, []);
  console.log(data);
  const customerForEdit = data;
  const [title, setTitle] = useState("");
  // Title couting  
  useEffect(() => {
    let _title = id ? "" : "Nuevo Usuario";
    // if (customerForEdit && id) {
    //   _title = `Editar Usuario '${customerForEdit.userInfo.firstName} ${customerForEdit.userInfo.lastName}'`;
    // }

    setTitle(_title);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* {actionsLoading && <ModalProgressBar />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
