import React from "react";
import { Route } from "react-router-dom";
import { CardtypesLoadingDialog } from "./cardtypes-loading-dialog/CardtypesLoadingDialog";
import { CardtypesEditDialog } from "./cardtypes-edit-dialog/CardtypesEditDialog";
import { CardtypesDeleteDialog } from "./cardtypes-delete-dialog/CardtypesDeleteDialog";
import { CardtypesFetchDialog } from "./cardtypes-fetch-dialog/CardtypesFetchDialog";
import { CardtypesUpdateStateDialog } from "./cardtypes-update-status-dialog/CardtypesUpdateStateDialog";
import { CardtypesUIProvider } from "./CardtypesUIContext";
import { CardtypesCard } from "./CardtypesCard";

export function CardtypesPage({ history }) {
  const customersUIEvents = {
    newCardtypesButtonClick: () => {
      history.push("/e-commerce/cardtypes/new");
    },
    openEditCardtypesDialog: (id) => {
      history.push(`/e-commerce/cardtypes/${id}/edit`);
    },
    openDeleteCardtypesDialog: (id) => {
      history.push(`/e-commerce/cardtypes/${id}/delete`);
    },
    openDeleteCardtypesDialog: () => {
      history.push(`/e-commerce/cardtypes/deleteCardtypes`);
    },
    openFetchCardtypesDialog: () => {
      history.push(`/e-commerce/cardtypes/fetch`);
    },
    openUpdateCardtypesStatusDialog: () => {
      history.push("/e-commerce/cardtypes/updateStatus");
    }
  }

  return (
    <CardtypesUIProvider customersUIEvents={customersUIEvents}>
      <CardtypesLoadingDialog />
      <Route path="/e-commerce/cardtypes/new">
        {({ history, match }) => (
          <CardtypesEditDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/cardtypes/:id/edit">
        {({ history, match }) => (
          <CardtypesEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/cardtypes/deleteCardtypes">
        {({ history, match }) => (
          <CardtypesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/cardtypes/:id/delete">
        {({ history, match }) => (
          <CardtypesDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/cardtypes/fetch">
        {({ history, match }) => (
          <CardtypesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/cardtypes/updateStatus">
        {({ history, match }) => (
          <CardtypesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/cardtypes");
            }}
          />
        )}
      </Route>
      <CardtypesCard />
    </CardtypesUIProvider>
  );
}
