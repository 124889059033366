import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { I18nProvider } from '../../_metronic/i18n';
import { MaterialThemeProvider } from '../../_metronic/layout';
import { AuthPage } from '../modules/Auth/pages/AuthPage';
import Error404Page from '../pages/Error404page';

const AuthRoutes = () => (
	<BrowserRouter>
		<MaterialThemeProvider>
			<I18nProvider>
				<Switch>
					<Route
						path="/(register|login)/"
						exact
						component={AuthPage}
					/>
					<Route
						path="*"
						component={() => <Redirect to="/login" />}
					/>
					<Route path="*" component={Error404Page} />
				</Switch>
			</I18nProvider>
		</MaterialThemeProvider>
	</BrowserRouter>
);

export default AuthRoutes;
