import React from "react";
import {
  ProductStatusCssClasses,
  ProductStatusTitles
} from "../../ProductsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      row.status == "progress" ? 
      "warning"
      : row.status == "payed" ?
      "success"
      :
      "danger"
    
    } label-inline`}
  >
    {row.status == "progress" ? 
    <span>inactiva</span>
    : row.status == "payed" ?
    <span>activa</span>
    :
    <span>cancelada</span>
    }
  </span>
);
