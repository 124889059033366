/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Switch, Redirect, Route } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import SVG from 'react-inlinesvg';

export function AuthPage() {	
	return (
		<>
			<div className="d-flex flex-column flex-root">
				<div
					className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
					id="kt_login"
				>
					<div
						className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
						style={{
							backgroundColor:"#1e5fda"
						}}
					>
						<div className="d-flex flex-row-fluid flex-column justify-content-between">
							<Link to="/" className="flex-column-auto mt-5">
								<SVG
									alt="Logo"
									className="logo-login max-h-70px"
									src={toAbsoluteUrl(
										'/media/logos/SolutionCards.svg'
									)}
								/>
							</Link>
							<div className="flex-column-fluid d-flex flex-column justify-content-center">
								<h3 className="font-size-h1 mb-5 text-white">
									Bienvenido a SolutionCards!
								</h3>
								<p className="font-weight-lighter text-white opacity-80">
									Un producto que 
								</p>
							</div>
							<div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
								<div className="opacity-70 font-weight-bold	text-white">
									&copy; 2021 SolutionCards
								</div>
								<div className="d-flex">
									
								</div>
							</div>
						</div>
					</div>
					<div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
						
						<div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
							<Switch>
								<ContentRoute path="/login" component={Login} />
								<ContentRoute path="/register" component={Registration} />
								<ContentRoute path="/forgot-password" component={ForgotPassword} />								
							</Switch>
						</div>
						<div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
							<div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
								&copy; 2021 SolutionCards
							</div>
							<div className="d-flex order-1 order-sm-2 my-2">
								
							</div>
						</div>
						{/* end::Mobile footer */}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Login*/}
			</div>
		</>
	);
}
