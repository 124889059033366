import React from "react";
import { Route } from "react-router-dom";
import { FieldtypesLoadingDialog } from "./fieldtypes-loading-dialog/FieldtypesLoadingDialog";
import { FieldtypesEditDialog } from "./fieldtypes-edit-dialog/FieldtypesEditDialog";
import { FieldtypesDeleteDialog } from "./fieldtypes-delete-dialog/FieldtypesDeleteDialog";
import { FieldtypesFetchDialog } from "./fieldtypes-fetch-dialog/FieldtypesFetchDialog";
import { FieldtypesUpdateStateDialog } from "./fieldtypes-update-status-dialog/FieldtypesUpdateStateDialog";
import { FieldtypesUIProvider } from "./FieldtypesUIContext";
import { FieldtypesCard } from "./FieldtypesCard";

export function FieldtypesPage({ history }) {
  const customersUIEvents = {
    newFieldtypesButtonClick: () => {
      history.push("/e-commerce/fieldtypes/new");
    },
    openEditFieldtypesDialog: (id) => {
      history.push(`/e-commerce/fieldtypes/${id}/edit`);
    },
    openDeleteFieldtypesDialog: (id) => {
      history.push(`/e-commerce/fieldtypes/${id}/delete`);
    },
    openDeleteFieldtypesDialog: () => {
      history.push(`/e-commerce/fieldtypes/deleteFieldtypes`);
    },
    openFetchFieldtypesDialog: () => {
      history.push(`/e-commerce/fieldtypes/fetch`);
    },
    openUpdateFieldtypesStatusDialog: () => {
      history.push("/e-commerce/fieldtypes/updateStatus");
    }
  }

  return (
    <FieldtypesUIProvider customersUIEvents={customersUIEvents}>
      <FieldtypesLoadingDialog />
      <Route path="/e-commerce/fieldtypes/new">
        {({ history, match }) => (
          <FieldtypesEditDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/fieldtypes/:id/edit">
        {({ history, match }) => (
          <FieldtypesEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/fieldtypes/deleteFieldtypes">
        {({ history, match }) => (
          <FieldtypesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/fieldtypes/:id/delete">
        {({ history, match }) => (
          <FieldtypesDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/fieldtypes/fetch">
        {({ history, match }) => (
          <FieldtypesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/fieldtypes/updateStatus">
        {({ history, match }) => (
          <FieldtypesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/fieldtypes");
            }}
          />
        )}
      </Route>
      <FieldtypesCard />
    </FieldtypesUIProvider>
  );
}
