import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { FieldtypesFilter } from "./fieldtypes-filter/FieldtypesFilter";
import { FieldtypesTable } from "./fieldtypes-table/FieldtypesTable";
import { FieldtypesGrouping } from "./fieldtypes-grouping/FieldtypesGrouping";
import { useFieldtypesUIContext } from "./FieldtypesUIContext";

export function FieldtypesCard() {
  const customersUIContext = useFieldtypesUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newFieldtypesButtonClick: customersUIContext.newFieldtypesButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Campos del Formulario">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newFieldtypesButtonClick}
          >
            Nuevo Campo para el Formulario
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <FieldtypesFilter />
        {customersUIProps.ids.length > 0 && <FieldtypesGrouping />}
        <FieldtypesTable />
      </CardBody>
    </Card>
  );
}
