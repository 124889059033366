import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../../providers/App";
import { UserContext } from "../../../providers/User";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

export const schema = yup.object().shape({
    email: yup
        .string()
        .min(3)
        .max(255)
        .email()
        .required("Especifica tu dirección de correo electrónico.")
        .trim(),
    password: yup
        .string()
        .min(5)
        .max(255)
        .required("Ingresa tu contraseña.")
        .trim()
        .matches(new RegExp("^[a-zA-Z0-9]{3,30}$")),
});
function Login(props) {
    const { axios } = useContext(AppContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { setSession } = useContext(UserContext);
    const { push } = useHistory();
    const submit = async (values) => {
        try {
            setLoading(true);
            const response = await axios.post("api/v1/login", values);
            if (response) {
                setLoading(false);
            }
            if (response.data.error) {
                throw new Error(response.data.msg);
            }
            setSession(response.data.token);
            push("/dashboard");
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='login-form login-signin'>
            <Formik
                onSubmit={submit}
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={schema}
            >
                {({ handleSubmit, values, errors, handleChange, touched }) => (
                    <form className='form' onSubmit={handleSubmit}>
                        <div className='pb-13 pt-lg-0 pt-5'>
                            <h3 className='font-weight-bolder text-dark font-size-h4 font-size-h1-lg'>
                                SolutionCards
                            </h3>
                            <span className='text-muted font-weight-bold font-size-h4'>
                                Eres un nuevo <span> </span>
                                 <Link
                                    to='/register'
                                    id='kt_login_signup'
                                    className='text-primary font-weight-bolder'
                                >
                                 Crear una cuenta
                                </Link>
                            </span>
                        </div>
                        {error && (
                            <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                                <div className='alert-text font-weight-bold'>
                                    {error}
                                </div>
                            </div>
                        )}
                        <div className='form-group'>
                            <label className='font-size-h6 font-weight-bolder text-dark'>
                                Email
                            </label>
                            <input
                                className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg ${touched.email &&
                                    errors.email &&
                                    "is-invalid"}`}
                                type='text'
                                name='email'
                                value={values.email}
                                onChange={handleChange("email")}
                            />
                            {errors.email && touched.email ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        {errors.email}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group'>
                            <div className='d-flex justify-content-between mt-n5'>
                                <label className='font-size-h6 font-weight-bolder text-dark pt-5'>
                                    Contraseña
                                </label>
                                <Link
                                    to=''
                                    className='text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5'
                                    id='kt_login_forgot'
                                >
                                    Olvidaste tu contraseña ?
                                </Link>
                            </div>
                            <input
                                className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg ${touched.password &&
                                    errors.password &&
                                    "is-invalid"}`}
                                type='password'
                                name='password'
                                value={values.password}
                                onChange={handleChange("password")}
                            />
                            {errors.password && touched.password ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        {errors.password}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='pb-lg-0 pb-5'>
                            <button
                                type='submit'
                                className='btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3'
                            >
                                <span>Iniciar sesión</span>
                                {loading && (
                                    <span className='ml-3 spinner spinner-white'></span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
