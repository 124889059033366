import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { SectionEditDialogHeader } from "./SectionEditDialogHeader";
import { SectionEditForm } from "./SectionEditForm";
import { useSectionUIContext } from "../SectionUIContext";

export function SectionEditDialog({ id, show, onHide }) {
  // Section UI Context
  const customersUIContext = useSectionUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initSection: customersUIContext.initSection,
    };
  }, [customersUIContext]);

  // Section Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Section by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer)).then(() => onHide());
    } else {
      // server request for updating customer
      dispatch(actions.updateCustomer(customer)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SectionEditDialogHeader id={id} />
      <SectionEditForm
        saveSection={saveCustomer}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initSection}
        onHide={onHide}
      />
    </Modal>
  );
}
