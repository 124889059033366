import Axios from "axios";
import { useState, useEffect, useContext } from "react";
import { ENDPOINT } from "../../../endpoint";
import { UserContext } from "../../providers/User";

const url = `${ENDPOINT}/api/v1/`;

export const useGet = <T>(
    uri: string,
    initialValue: T,
    config?: { auth: boolean }
) => {
    const { getToken } = useContext(UserContext);
    const [data, setData] = useState<T>(initialValue);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        let cancel: any;
        const requestUrl = url + uri;
        const cancelToken = new Axios.CancelToken((c: any) => (cancel = c));
        const requestConfig = {
            cancelToken,
            headers: {
                authorization: getToken(),
            },
        };
        Axios.get(requestUrl, requestConfig)
            .then((response) => {
                if (response.data.error) {
                    throw new Error(response.data.message);
                }
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
                setLoading(false);
            });

        return () => cancel();
    }, [getToken, url, uri]);

    return {
        loading,
        data,
        error,
    };
};

export const usePost = <T>(
    uri: string,
    d: object,
    initialValue: T,
    config?: { auth: boolean }
) => {
    const { getToken } = useContext(UserContext);
    const [data, setData] = useState<T>(initialValue);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        let cancel: any;
        const requestUrl = url + uri;
        const cancelToken = new Axios.CancelToken((c: any) => (cancel = c));
        const requestConfig = {
            cancelToken,
            headers: {
                authorization: getToken(),
            },
        };
        Axios.post(requestUrl, d, requestConfig)
            .then((response) => {
                if (response.data.error) {
                    throw new Error(response.data.message);
                }
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
                setLoading(false);
            });

        return () => cancel();
    }, [getToken, url, uri]);

    return {
        loading,
        data,
        error,
    };
};
