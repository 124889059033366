import React from "react";
import { Route } from "react-router-dom";
import { SectionLoadingDialog } from "./section-loading-dialog/SectionLoadingDialog";
import { SectionEditDialog } from "./section-edit-dialog/SectionEditDialog";
import { SectionDeleteDialog } from "./section-delete-dialog/SectionDeleteDialog";
import { SectionFetchDialog } from "./section-fetch-dialog/SectionFetchDialog";
import { SectionUpdateStateDialog } from "./section-update-status-dialog/SectionUpdateStateDialog";
import { SectionUIProvider } from "./SectionUIContext";
import { SectionCard } from "./SectionCard";

export function SectionPage({ history }) {
  const customersUIEvents = {
    newSectionButtonClick: () => {
      history.push("/e-commerce/section/new");
    },
    openEditSectionDialog: (id) => {
      history.push(`/e-commerce/section/${id}/edit`);
    },
    openDeleteSectionDialog: (id) => {
      history.push(`/e-commerce/section/${id}/delete`);
    },
    openDeleteSectionDialog: () => {
      history.push(`/e-commerce/section/deleteSection`);
    },
    openFetchSectionDialog: () => {
      history.push(`/e-commerce/section/fetch`);
    },
    openUpdateSectionStatusDialog: () => {
      history.push("/e-commerce/section/updateStatus");
    }
  }

  return (
    <SectionUIProvider customersUIEvents={customersUIEvents}>
      <SectionLoadingDialog />
      <Route path="/e-commerce/section/new">
        {({ history, match }) => (
          <SectionEditDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/section/:id/edit">
        {({ history, match }) => (
          <SectionEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/section/deleteSection">
        {({ history, match }) => (
          <SectionDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/section/:id/delete">
        {({ history, match }) => (
          <SectionDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/section/fetch">
        {({ history, match }) => (
          <SectionFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <Route path="/e-commerce/section/updateStatus">
        {({ history, match }) => (
          <SectionUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/e-commerce/section");
            }}
          />
        )}
      </Route>
      <SectionCard />
    </SectionUIProvider>
  );
}
