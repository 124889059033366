import React, { Fragment } from 'react';
import { AppProvider } from './App';
import { UserProvider } from './User';

const Providers = ({children}) => {	
	return(
	<Fragment>
		<AppProvider>
			<UserProvider>
				{children}
			</UserProvider>
		</AppProvider>
	</Fragment>
	)
};

export default Providers;
