import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { I18nProvider } from '../../_metronic/i18n';
import {  MaterialThemeProvider } from '../../_metronic/layout';

const EditorRoutes = () => (
	<BrowserRouter>
		<MaterialThemeProvider>
			<I18nProvider>
				<Switch>
					<Route />
				</Switch>
			</I18nProvider>
		</MaterialThemeProvider>
	</BrowserRouter>
);

export default EditorRoutes;
