import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {DashboardPage} from "../pages/DashboardPage";
import { I18nProvider } from '../../_metronic/i18n';
import {  ContentRoute, MaterialThemeProvider } from '../../_metronic/layout';
import {Layout} from "../../_metronic/layout";
import ECommercePage from '../modules/ECommerce/pages/eCommercePage';
import { MyPage } from '../pages/MyPage';
import { Constructor } from '../pages/Constructor';

const AdminRoutes = () => (
	<BrowserRouter>
		<I18nProvider>
			<MaterialThemeProvider>
				<Layout>
					<Switch>						
						<ContentRoute path="/" exact component={MyPage}/>												
						<ContentRoute path="/asd" exact component={DashboardPage}/>												
						<Route path="/e-commerce" component={ECommercePage}/>
						<ContentRoute path="/constructor" exact component={Constructor}/>												
						<Route path="*" component={() => <Redirect to="/" /> } />
					</Switch>
				</Layout>
			</MaterialThemeProvider>
		</I18nProvider>
	</BrowserRouter>
);

export default AdminRoutes;
