// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { usePost } from "../../../../../hooks/useGet/index.ts";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import { AppContext } from "../../../../../providers/App";
import { UserContext } from "../../../../../providers/User";

// Validation schema
const FieldtypesEditSchema = Yup.object().shape({
  base_url: Yup.string()
    .min(3, "Minimo de 3 caractreres")    
    .required("La URL base es requerida"),
    cta: Yup.string()     
    .required("El texto por default requerido"),
    fieldKey: Yup.string()
    .required("El nombre del campo es requerido"),
    icon: Yup.string()
    .required("El icono es requerido"),
    section_id: Yup.string()
    .required("La seccion es requerida")  
});

export function FieldtypesEditForm({  
  customer,
  actionsLoading,
  onHide,
}) {
  const { axios } = useContext(AppContext);
  const { getToken } = useContext(UserContext);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{            
            firstName: "",          
            lastName: "",            
            email: "",
            username: "",
        }}        
        validationSchema={FieldtypesEditSchema}
        onSubmit={ async (values) =>  {
          try {
            const response = await axios.post('/api/v1/fieldTypes', values, {"headers": {"authorization": getToken()}});
            if (response.data.error) {
              throw new Error(response.data.msg);
            }            
            console.log(response);
            onHide();
          } catch (error) {
            console.log(error.message);
          }
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>                
              )}
              <Form className="form form-label-right">                
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-4">                    
                    <Field
                      name="fieldKey"
                      component={Input}
                      placeholder="Ingresa el nombre para el input"
                      label="Nombre del Campo"
                    />
                  </div>
                  {/* Last Name */}
                  <div className="col-lg-4">
                    <Field
                      name="cta"
                      component={Input}
                      placeholder="Ingresa el texto por defecto para el input"
                      label="Texto default"
                    />
                  </div>
                  {/* username */}
                  <div className="col-lg-4">
                    <Field
                      name="base_url"
                      component={Input}
                      placeholder="Ingresa la URL base"
                      label="URL Base"
                    />
                  </div>
                  <div className="col-lg-4 my-3">
                    <Field
                      name="icon"
                      component={Input}
                      placeholder="Id del icono"
                      label="Icono"
                    />
                  </div>
                  <div className="col-lg-4 my-3">
                    <Field
                      name="section_id"
                      component={Input}
                      placeholder="Seccion del formulario"
                      label="Seccion dentro el Formulario"
                    />
                  </div>
                </div>                                
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
