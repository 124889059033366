// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function AvatarColumnFormatter(cellContent, row) {
  return (
    <>
        <span>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-40 symbol-light-primary flex-shrink-0"> 
                    {row.userInfo.avatar == "" ?
                        <span className="symbol-label font-size-h4 font-weight-bold">{row.userInfo.firstName[0]}</span>                        
                        :                        
                        <img src={`/media/users/${row.userInfo.avatar}`} alt={row.username}/>
                    }
                </div>
                <div className="ml-4">
                    <div className="text-dark-75 font-weight-bolder font-size-lg mb-0">
                        {row.userInfo.firstName} {row.userInfo.lastName}
                    </div> 
                    <a className="text-muted font-weight-bold text-hover-primary">{row.email}</a>
                </div>
            </div>
        </span>        
    </>
  );
}


