import React, { Fragment } from "react";
import SVG from "react-inlinesvg";
import { ENDPOINT } from "../../../endpoint";

export default ({ buttons }) => {
    return (
        <Fragment>
            {buttons.map((button, index) => {
                if (!button.enabled) {
                    return null;
                }
                return (
                    <a key={index} href={button.href} className='btn big'>
                        <SVG
                            src={`${ENDPOINT}/images/icons/${button.icon}.svg`}
                        />
                        <span>{button.description}</span>
                    </a>
                );
            })}
        </Fragment>
    );
};
