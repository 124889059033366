import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./CardtypesUIHelpers";

const CardtypesUIContext = createContext();

export function useCardtypesUIContext() {
  return useContext(CardtypesUIContext);
}

export const CardtypesUIConsumer = CardtypesUIContext.Consumer;

export function CardtypesUIProvider({customersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCardtypes = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCardtypes,
    newCardtypesButtonClick: customersUIEvents.newCardtypesButtonClick,
    openEditCardtypesDialog: customersUIEvents.openEditCardtypesDialog,
    openDeleteCardtypesDialog: customersUIEvents.openDeleteCardtypesDialog,
    openDeleteCardtypesDialog: customersUIEvents.openDeleteCardtypesDialog,
    openFetchCardtypesDialog: customersUIEvents.openFetchCardtypesDialog,
    openUpdateCardtypesStatusDialog: customersUIEvents.openUpdateCardtypesStatusDialog
  };

  return <CardtypesUIContext.Provider value={value}>{children}</CardtypesUIContext.Provider>;
}