import React, { Fragment } from "react";
import SVG from "react-inlinesvg";
import Button from "./Button";
import { ENDPOINT } from "../../../endpoint";

export default ({ buttons, name, description, avatar, bg, icons }) => {
    return (
        <Fragment>
            <div className='content' style={{ overflow: "hidden", padding: 0 }}>
                <div
                    className='cover'
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <div className='avatar'>
                        <img src={avatar} alt={name} />
                        <div className='verified'></div>
                    </div>
                </div>

                <div className='focus'>
                    <div className='infocard'>
                        <h1>{name}</h1>
                        <p>{description}</p>
                    </div>

                    <Button buttons={buttons} />
                </div>

                <ul className='social'>
                    {icons.map((icon, index) => {
                        if (!icon.enabled) {
                            return null;
                        }
                        return (
                            <li key={index}>
                                <a
                                    href={icon.href}
                                    target='_blank'
                                    title='menu'
                                >
                                    <SVG
                                        src={`${ENDPOINT}/images/icons/${icon.icon}.svg`}
                                    />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Fragment>
    );
};
