import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CardtypesFilter } from "./cardtypes-filter/CardtypesFilter";
import { CardtypesTable } from "./cardtypes-table/CardtypesTable";
import { CardtypesGrouping } from "./cardtypes-grouping/CardtypesGrouping";
import { useCardtypesUIContext } from "./CardtypesUIContext";

export function CardtypesCard() {
  const customersUIContext = useCardtypesUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCardtypesButtonClick: customersUIContext.newCardtypesButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Tipos de Tarjetas">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCardtypesButtonClick}
          >
            Nuevo Tipo de Tarjeta
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CardtypesFilter />
        {customersUIProps.ids.length > 0 && <CardtypesGrouping />}
        <CardtypesTable />
      </CardBody>
    </Card>
  );
}
