import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { FieldtypesEditDialogHeader } from "./FieldtypesEditDialogHeader";
import { FieldtypesEditForm } from "./FieldtypesEditForm";
import { useFieldtypesUIContext } from "../FieldtypesUIContext";

export function FieldtypesEditDialog({ id, show, onHide }) {
  // Fieldtypes UI Context
  const customersUIContext = useFieldtypesUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initFieldtypes: customersUIContext.initFieldtypes,
    };
  }, [customersUIContext]);

  // Fieldtypes Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Fieldtypes by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer)).then(() => onHide());
    } else {
      // server request for updating customer
      dispatch(actions.updateCustomer(customer)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <FieldtypesEditDialogHeader id={id} />
      <FieldtypesEditForm
        saveFieldtypes={saveCustomer}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initFieldtypes}
        onHide={onHide}
      />
    </Modal>
  );
}
