import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { AppContext } from "./App";
import JwtDecode from "jwt-decode";

export const UserContext = createContext({
    user: {
        id: 0,
        role: "",
        email: "",
        username: "",
    },
    token: "",
    setSession: () => null,
    closeSession: () => null,
    getToken: () => "",
});

export const UserProvider = ({ children }) => {
    const { setLoading, axios } = useContext(AppContext);
    const [user, setUser] = useState(false);
    const [token, setToken] = useState("");
    const setSession = useCallback((token) => {
        const body = JwtDecode(token);
        setUser((prev) => ({ ...prev, ...body }));
        setToken(token);
        setLoading(false);
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get("/api/v1/current")
            .then((response) => {
                if (response.data.error) {
                    throw new Error(response.data.msg);
                }
                setSession(response.data.token);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [setLoading, setSession]);

    const closeSession = async () => {
        await axios.get("/api/v1/logout");
        setToken("");
        setUser(false);
    };

    function getToken() {
        if (token === "") {
            return "Bearrer ";
        }
        const { exp } = JwtDecode(token);
        if (exp < Date.now().valueOf() / 1000) {
            axios
                .get("/api/v1/auth/current")
                .then((response) => {
                    if (response.data.error) {
                        throw new Error(response.data.msg);
                    }
                    setSession(response.data.token);
                })
                .catch(() => {
                    setToken("");
                    setUser(false);
                });
        }
        return `Bearrer ${token}`;
    }

    return (
        <UserContext.Provider
            value={{ setSession, user, token, closeSession, getToken }}
        >
            {children}
        </UserContext.Provider>
    );
};
