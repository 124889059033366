import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SectionFilter } from "./section-filter/SectionFilter";
import { SectionTable } from "./section-table/SectionTable";
import { SectionGrouping } from "./section-grouping/SectionGrouping";
import { useSectionUIContext } from "./SectionUIContext";

export function SectionCard() {
  const customersUIContext = useSectionUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newSectionButtonClick: customersUIContext.newSectionButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Secciones del Formulario">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newSectionButtonClick}
          >
            Nueva Sección
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <SectionFilter />
        {customersUIProps.ids.length > 0 && <SectionGrouping />}
        <SectionTable />
      </CardBody>
    </Card>
  );
}
