/**
 * Entry application component used to compose providers and render Routes.
 * */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LayoutSplashScreen } from '../_metronic/layout';
import Providers from './providers';
import Router from './routes/index';

export default function App({ store, persistor, basename }) {
	return (
		/* Provide Redux store */
		<Provider store={store}>
			<PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
				<React.Suspense fallback={<LayoutSplashScreen />}>
					<Providers>
					<Router/>
					</Providers>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);
} 