import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { AppContext } from "../../../providers/App";
import { UserContext } from "../../../providers/User";
import * as auth from "../_redux/authRedux";

export const schema = yup.object().shape({
	email: yup
		.string()
		.min(3)
		.max(255)
		.email()
		.required('Especifica tu dirección de correo electrónico.')
		.trim(),
	password: yup
		.string()
		.min(5)
		.max(255)
		.required('Ingresa tu contraseña.')
		.trim()
		.matches(new RegExp('^[a-zA-Z0-9]{3,30}$')),
	username: yup
		.string()
		.min(3)
		.max(255)
		.trim()
		.required('Ingresa tu nombre de usuario.'),
	comfirmPassword: yup.string()
     .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden.')

});

function Registration() {
  const { axios } = useContext(AppContext);
  const [error, setError] = useState('');
	const { setSession } = useContext(UserContext);
	const { push } = useHistory();
	const submit = async (values) => {
		try {
			const response = await axios.post('/api/v1/signup', values);
			if (response.data.error) {
				throw new Error(response.data.msg);
			}
			setSession(response.data.token);
			push('/');
		} catch (error) {
			setError(error.message);
		}
	};
  return (    
        <div className="login-form login-signup-on">							
          <Formik
            onSubmit={submit}
            initialValues={{
              email: '',
              password: '',
              username: '',
              comfirmPassword: '',
            }}
            validationSchema={schema}
            validateOnChange={true}
          >
          {({ handleSubmit, values, errors, handleChange, touched }) => (
          <form className="form" autoComplete="off" noValidate  onSubmit={handleSubmit}>								
            <div className="pb-13 pt-lg-0 pt-5">
              <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Registro</h3>
              <p className="text-muted font-weight-bold font-size-h4">Ingresa los datos para la creación de tú cuenta</p>
            </div>					
            {error && (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">
									{error}
								</div>
							</div>
						)}											
            <div className="form-group">
              <input className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 ${touched.username && errors.username && 'is-invalid'}`} type="text" placeholder="Fullname" name="username" value={values.username} onChange={handleChange('username')}/>
              {errors.username && touched.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.username}</div>
                </div>
              ) : null}
            </div>																
            <div className="form-group">
              <input className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 ${touched.email && errors.email && 'is-invalid'}`} type="email" placeholder="Email" name="email" value={values.email} onChange={handleChange('email')}/>
              {errors.email && touched.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.email}</div>
                </div>
              ) : null}
            </div>																
            <div className="form-group">
              <input className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 ${touched.password && errors.password && 'is-invalid'}`} type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange('password')}/>
              {errors.password && touched.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.password}</div>
                </div>
              ) : null}
            </div>																
            <div className="form-group">
              <input className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 ${touched.comfirmPassword && errors.comfirmPassword && 'is-invalid'}`} type="password" placeholder="Confirm password" name="comfirmPassword" value={values.comfirmPassword} onChange={handleChange('comfirmPassword')} />
              {errors.comfirmPassword && touched.comfirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.comfirmPassword}</div>
                </div>
              ) : null}
            </div>																
            <div className="form-group">
              <label className="checkbox mb-0">
              <input type="checkbox" name="agree" />Estoy de acuerdo con los <b> </b>
              <Link to=""> terminos y condiciones</Link>.
              <span></span></label>
            </div>																
            <div className="form-group d-flex flex-wrap pb-lg-0 pb-3">
              <button type="submit" className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">Enviar</button>
              <Link to="/" className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">Cancelar</Link>
            </div>								
          </form>	
          )}
          </Formik>						
				</div>																									
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
